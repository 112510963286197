import React from 'react'
import PropTypes from "prop-types"

import Illustrations from '../../Illustrations/Illustrations'

export default function Treatment( props ) {
	const data = props.input
	const index = props.index
	const altStyling = index % 2 ? 'treatment--alt' : ''

	return (
		<div className={`treatment ${ altStyling }`}>
			<div className="treatment__wrapper wrapper">
				<div className="treatment__text-container">
					<div className="treatment__name">{ data.name }</div>
					<div className="treatment__description">{ data.description }</div>

					<div className="treatment__label">
						Effects
					</div>

					<ul className="treatment__list">
						{ data.effects.map( effect => (
							<li className="treatment__list-item" key={ effect.id }>{ effect.effect }</li>
						) ) }
					</ul>

					<div className="treatment__label">{ data.whyChooseTitle }</div>
					<ul className="treatment__list">
						{ data.whyChoose.map( choose => (
							<li className="treatment__list-item" key={ choose.id }>{ choose.whyChooseItem }</li>
						) ) }
					</ul>
					
					<ul className="treatment__price-list">
						{ data.pricing.map( price => (
							<li className="treatment__price" key={ price.id }>{ price.price }</li>
						) ) }
					</ul>
				</div>

				<div className="treatment__illustration-container">
					<Illustrations class="treatment__illustration" illustration={ data.illustration } />
				</div>
			</div>
		</div>
	)
}

Treatment.propTypes = {
	input: PropTypes.object.isRequired
};