import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

export default function SpecialDay( props ) {

	const data = props.input
	const index = props.index

	return (
		<section className={`special-day wrapper ${ index % 2 === 0 ? 'special-day--reverse' :'' }`}>
			<div className="special-day__image-container">
				<Img className="special-day__image" fluid={{ ...data.image.imageFile.childImageSharp.fluid, aspectRatio: 1 }} />
			</div>
			<div className="special-day__text-container">
				<div className="special-day__heading-container" >
					<span className="special-day__heading">{ data.title }</span>
				</div>

				<ReactMarkdown source={ data.description } className="special-day__description" />

				<div className="special-day__input-container">
					<div className="special-day__meta">Duration: { data.duration }</div>
					<div className="special-day__meta">Availability: { data.availability }</div>
					<div className="special-day__price-container">
						<Link
							to="/contact-us"
							state={{ booking: data.title }}
							className="button button--primary button--large button--no-margin">Book Now</Link>
						<div className="special-day__price">{ data.price }</div>
					</div>
				</div>
			</div>
		</section>
	)
}

SpecialDay.propTypes = {
	input: PropTypes.object.isRequired
};