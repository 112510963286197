import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"

import House from "../../../images/house.png"

export default function PrimaryBanner( props ) {

	const data = props.input

	return (
		<>
			<section className="primary-banner">
				<div className="primary-banner__wrapper wrapper">
					<div className="primary-banner__text-container">
						<h2 className="primary-banner__heading">
							{ data.heading }
						</h2>
						<Link to={ data.ctaLink } className="primary-banner__link">
							{ data.ctaText }
						</Link>
					</div>
					<div className="primary-banner__image-container">
						<img src={ House } className="primary-banner__image" alt="" />
					</div>
				</div>
			</section>

		</>
	)
}

PrimaryBanner.propTypes = {
	input: PropTypes.object.isRequired
};