import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'

import Illustrations from '../../Illustrations/Illustrations'

export default function AboutUsHero( props ) {

	const data = props.input

	return (
		<section className="general-illustration-banner">
			<div className="general-illustration-banner__wrapper wrapper">
				<div className="general-illustration-banner__text-container">
					<h1 className="general-illustration-banner__heading">
						{ data.generalIllustrationTitle }
					</h1>
					<ReactMarkdown source={ data.generalIllustrationDescription } escapeHtml={ false } className="general-illustration-banner__description" />
					
					{ data.generalIllustrationLinkText && data.generalIllustrationLinkUrl &&
						<Link to={ data.generalIllustrationLinkUrl } className="general-illustration-banner__link">
							{ data.generalIllustrationLinkText }
						</Link>
					}
				</div>
				<div className="general-illustration-banner__image-container">
					<Illustrations class="general-illustration-banner__image" illustration={ data.generalIllustrationImage } />
				</div>
			</div>
		</section>
	)
}

AboutUsHero.propTypes = {
	input: PropTypes.object.isRequired
};