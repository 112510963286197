import React from 'react'

import GeneralIllustrationBanner from './DynamicZoneComponents/GeneralIllustrationBanner'
import AboutContentBanner from './DynamicZoneComponents/AboutContentBanner'
import AboutHero from './DynamicZoneComponents/AboutHero'
import ColourBanner from './DynamicZoneComponents/ColourBanner'
import CategoryGrid from './DynamicZoneComponents/CategoryGrid'
import FounderIllustration from './DynamicZoneComponents/FounderIllustration'
import FounderImage from './DynamicZoneComponents/FounderImage'
import HeroBanner from './DynamicZoneComponents/HeroBanner'
import IllustrationBanner from './DynamicZoneComponents/IllustrationBanner'
import ImageBanner from './DynamicZoneComponents/ImageBanner'
import ImageBannerSplit from './DynamicZoneComponents/ImageBannerSplit'
import PrimaryBanner from './DynamicZoneComponents/PrimaryBanner'
import ServicesBanner from './DynamicZoneComponents/ServicesBanner'
import SpecialDay from './DynamicZoneComponents/SpecialDay'
import TabComponent from './DynamicZoneComponents/TabComponent'
import QuoteBanner from './DynamicZoneComponents/QuoteBanner'
import Treatment from './DynamicZoneComponents/Treatment'
import BeautyHeader from './DynamicZoneComponents/BeautyHeader'

export default function DynamicZone( { components } ) {

	return (
		<>
			{ components.map(( comp, index ) => {
				switch (comp.__typename) {
					case 'StrapiQuery_ComponentFounderFounderIllustration':
						return <FounderIllustration key={ index } input={ comp } />
					case 'StrapiQuery_ComponentAboutAboutHero':
						return <AboutHero key={ index } input={ comp } />
					case 'StrapiQuery_ComponentAboutQuoteBanner':
						return <QuoteBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentAboutServicesBanner':
						return <ServicesBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentFounderFounderImage':
						return <FounderImage key={ index } input={ comp } />
					case 'StrapiQuery_ComponentGeneralHeroBanner':
						return <HeroBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomeImageBanner':
						return <ImageBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomeColourBanner':
						return <ColourBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomePrimaryBanner':
						return <PrimaryBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomeIllustrationBanner':
						return <IllustrationBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomeProductCategoryGrid':
						return <CategoryGrid key={ index } input={ comp } />
					case 'StrapiQuery_ComponentMakeoverTabs':
						return <TabComponent key={ index } input={ comp } />
					case 'StrapiQuery_ComponentMakeoverImageBannerSplit':
						return  <ImageBannerSplit key={ index } input={ comp } index={ index }/>
					case 'StrapiQuery_ComponentSpecialSpecialDayPage':
						return  <SpecialDay key={ index } input={ comp } index={ index } />
					case 'StrapiQuery_ComponentGeneralGeneralIllustrationBanner':
						return  <GeneralIllustrationBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentAboutAboutContentBanner':
						return <AboutContentBanner key={ index } input={ comp } />
					case 'StrapiQuery_ComponentBeautyTreatments':
						return <Treatment key={ index } input={ comp } index={ index } />
					case 'StrapiQuery_ComponentBeautyHeader':
						return <BeautyHeader key={ index } input={ comp } />
					default:
						return null
				}
			}) }
		</>
	)
}