import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { formatTitle } from '../../../helpers'

import Illustrations from '../../Illustrations/Illustrations'

export default function ImageBanner( props ) {

	const data = props.input

	return (
		<>
			<section className={`image-banner ${ !data.alignImageLeft ? '': 'image-banner--reverse'} ${ data.imageMainContent ? 'image-banner--image-primary': '' }`}>
				<div className="image-banner__text-wrapper">
					<div className="image-banner__text-container">
						<h2 className="image-banner__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) }></h2>
						{ data.ctaLink &&
							<Link to={ data.ctaLink } className="image-banner__link">
								{ data.ctaText }
							</Link>
						}

						{ !data.ctaLink &&
							<Illustrations class="image-banner__background-image u-hide-phablet" illustration={ 'HeartsThick' } />
						}
					</div>
				</div>
				<div className="image-banner__image-container">
					<Img className="image-banner__image" fluid={ data.image.imageFile.childImageSharp.fluid } />
				</div>
			</section>

		</>
	)
}

ImageBanner.propTypes = {
	input: PropTypes.object.isRequired
};