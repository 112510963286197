import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Illustrations from '../../Illustrations/Illustrations'

export default function IllustrationBanner( props ) {

	const data = props.input

	return (
		<>
			<section className="illustration-banner">
				<div className={`illustration-banner__wrapper ${ !data.alignImageLeft ? 'illustration-banner__wrapper--reverse' : '' } wrapper`}>
					<div className="illustration-banner__text-container">
						<h2 className="illustration-banner__heading">
							{ data.heading }
						</h2>
						<Link to={ data.ctaLink } className="illustration-banner__link">
							{ data.ctaText }
						</Link>
					</div>
					<div className="illustration-banner__image-container">
						<Illustrations class="illustration-banner__image" illustration={ data.illustration } />
					</div>
				</div>
			</section>

		</>
	)
}

IllustrationBanner.propTypes = {
	input: PropTypes.object.isRequired
};