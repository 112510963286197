import React from 'react'
import PropTypes from 'prop-types'

export default function QuoteBanner( props ) {

	const data = props.input

	return (
		<section className="quote-banner">
			<div className="quote-banner__wrapper">
				<div className="quote-banner__text-container">
					<h2 className="quote-banner__heading">
						{ data.quoteText}
					</h2>
				</div>
				{/* { data.image &&  */
					<div className="quote-banner__image-container">
						{/* <Img fluid={ data.image.imageFile.childImageSharp.fluid } className="quote-text_image" /> */}
					</div>
				}
			</div>
		</section>
	)
}

QuoteBanner.propTypes = {
	input: PropTypes.object.isRequired
};