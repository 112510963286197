import React from 'react'
import PropTypes from "prop-types"

import Tabs from '../../Tabs/Tabs'
import MakeoverTabItem from '../../MakeoverDays/MakeoverTabItem'

export default function TabsComponent( props ) {

	const data = props.input

	return (
		<div className="wrapper">
			<div className="tabs__header">
				{ data.componentHeading }
			</div>
			<Tabs transitionDuration={ 350 }>
				{ data.tabItem.map( tab => (
					<div key={ tab.id } tabName={ tab.tabName }>
						<MakeoverTabItem data={ tab } />
					</div>
				))}
			</Tabs>
		</div>
	)
}

TabsComponent.propTypes = {
	input: PropTypes.object.isRequired
};