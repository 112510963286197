import React from 'react'
import PropTypes from "prop-types"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'

export default function FounderImage( props ) {

	const data = props.input

	return (
		<section className={`founder-banner wrapper ${ data.founderImageAlignment === "Right" ? 'founder-banner--reverse' : '' }`}>
			<div className="founder-banner__image-container">
				<Img className="founder-banner__image" fluid={{ ...data.founderImage.imageFile.childImageSharp.fluid, aspectRatio: 1 }} />
			</div>
			<div className="founder-banner__text-container">
				<div className="founder-banner__heading-container" >
					<span className="founder-banner__heading">{ data.founderImageTitle }</span>
				</div>

				<ReactMarkdown source={ data.founderImageInfo } className="founder-banner__description" />
			</div>
		</section>
	)
}

FounderImage.propTypes = {
	input: PropTypes.object.isRequired
};