import React from 'react'
import PropTypes from "prop-types"
import Img from "gatsby-image"

export default function HeroBanner( props ) {

	const data = props.input

	return (
		<>
			<section className={`hero-banner hero-banner--${data.imageFocus.toLowerCase()}`}>
				<div className="hero-banner__wrapper">
					<div className="hero-banner__text-container">
						{ data.heroTitle &&
							<h1 className="hero-banner__heading">
								{ data.heroTitle }
							</h1>
						}
						{ data.heroDescription &&
							<h4 className="hero-banner__description">
								{ data.heroDescription }
							</h4>
						}
					</div>
					<div className="hero-banner__image-container">
						<Img fluid={{ ...data.image.imageFile.childImageSharp.fluid, aspectRatio: 16/9 }} className="hero-banner__image" />
					</div>
				</div>
			</section>
		</>
	)
}

HeroBanner.propTypes = {
	input: PropTypes.object.isRequired
};