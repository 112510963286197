import React from 'react'
import PropTypes from "prop-types"
import ReactMarkdown from 'react-markdown'

import Illustrations from '../../Illustrations/Illustrations'

export default function FounderIllustration( props ) {

	const data = props.input

	return (
		<section className={`founder-banner wrapper ${ data.founderIllustrationAlignment === "Right" ? 'founder-banner--reverse' : '' }`}>
			<div className="founder-banner__image-container">
				<Illustrations class="founder-banner__image founder-banner__image--illustration" illustration={ data.founderIllustrationImage} />
			</div>
			<div className="founder-banner__text-container">
				<div className="founder-banner__heading-container" >
					<span className="founder-banner__heading">{ data.founderIllustrationTitle }</span>
				</div>

				<ReactMarkdown source={ data.founderIllustrationInfo } className="founder-banner__description" />

			</div>
		</section>
	)
}

FounderIllustration.propTypes = {
	input: PropTypes.object.isRequired
};
