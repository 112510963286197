import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import { AnchorLink } from "gatsby-plugin-anchor-links";

export default ( props ) => {

	const { data } = props

	return (
		<div className="makeover-tab-item">
			<div className="makeover-tab-item__image-container">
				<Img fluid={{ ...data.contentImage.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="makeover-tab-item__image" />
			</div>
			<div className="makeover-tab-item__text-container">
				<div className="makeover-tab-item__text-wrapper">
					<div className="makeover-tab-item__heading">
						{ data.contentTitle }
					</div>

					<ReactMarkdown source={ data.contentDescription } escapeHtml={ false } className="makeover-tab-item__description" />

					<AnchorLink to="/makeover-days#book-makeover-day" className="makeover-tab-item__link">
						Book a makeover day now
					</AnchorLink>
				</div>
			</div>
		</div>
	)
}