import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
export default function CategoryGrid( props ) {

	const data = props.input

	return (
		<>
			<section className="category-grid">
				<div className="category-grid__wrapper wrapper">
					<h2 className="category-grid__heading">
						{ data.heading }
					</h2>
					<div className="category-grid__grid">
						{ data.categories.map( category => (
							<Link
								to={ `/${category.main_categories[0].slug}` }
								state={{ categoryId: category.id }}
								className="category-grid__item" key={ category.id }>
								<Img fluid={ category.thumbnail.imageFile.childImageSharp.fluid } className="category-grid__image" />
								<h3 className="category-grid__item-label">{ category.label }</h3>
								<span className="category-grid__item-count">{ category.products.length } items</span>
							</Link>
						) ) }
					</div>
					<Link to="/clothing" className="category-grid__view-all">
						View All
					</Link>
				</div>
			</section>

		</>
	)
}

CategoryGrid.propTypes = {
	input: PropTypes.object.isRequired
};