import React from 'react'
import PropTypes from 'prop-types'

export default function AboutHero( props ) {

	const data = props.input

	return (
		<section className={`about-hero ${ !data.aboutHeroStrapline ? 'about-hero--secondary' : '' }`}>
			<div className="about-hero__wrapper">
				<div className="about-hero__text-container">
						<h1 className="about-hero__heading">
							{ data.aboutHeroTitle }
						</h1>
						<h4 className="about-hero__description">
							{ data.aboutHeroInfo }
						</h4>
					{ data.aboutHeroStrapline &&
						<h4 className="about-hero__strapline">
							{ data.aboutHeroStrapline }
						</h4>
					}
				</div>
			</div>
		</section>
	)
}

AboutHero.propTypes = {
	input: PropTypes.object.isRequired
};