import React from 'react'
import PropTypes from "prop-types"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import { Link } from "gatsby"

export default function ImageBannerSplit( props ) {

	const data = props.input

	return (
		<section className={`image-banner-split wrapper ${ data.imageBannerSplitAlignment === "Right" ? 'image-banner-split--reverse' : '' }`}>
			<div className="image-banner-split__image-container">
				<Img className="image-banner-split__image" fluid={{ ...data.imageBannerSplitImage.imageFile.childImageSharp.fluid, aspectRatio: 1 }} />
			</div>
			<div className="image-banner-split__text-container">
				<div className="image-banner-split__heading-container" >
					<span className="image-banner-split__heading">{ data.imageBannerSplitTitle }</span>
				</div>

				<ReactMarkdown source={ data.imageBannerSplitDescription } className="image-banner-split__description" />

				{ data.imageBannerSplitLinkText && data.imageBannerSplitLinkUrl &&
					<div className="image-banner-split__input-container">
						<Link to={ data.imageBannerSplitLinkUrl } className="image-banner-split__link">
							{ data.imageBannerSplitLinkText }
						</Link>
					</div>
				}
			</div>
		</section>
	)
}

ImageBannerSplit.propTypes = {
	input: PropTypes.object.isRequired
};