import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default function ColourBanner( props ) {

	const data = props.input

	return (
		<>
			<section className={`colour-banner colour-banner--${ data.backgroundColour.toLowerCase() }`}>
				<div className="colour-banner__wrapper wrapper">
					<div className="colour-banner__text-container">
						<h2 className="colour-banner__heading">
							{ data.heading }
						</h2>
						<Link to={ data.ctaLink } className="colour-banner__link">
							{ data.ctaText }
						</Link>
					</div>
					<div className="colour-banner__image-container">
						<Img fluid={ data.image.imageFile.childImageSharp.fluid } className="colour-banner__image" />
					</div>
				</div>
			</section>

		</>
	)
}

ColourBanner.propTypes = {
	input: PropTypes.object.isRequired
};