import React from 'react'
import PropTypes from "prop-types"

export default function BeautyHeader( props ) {

	const data = props.input

	return (
		<section className="beauty-header">
			<div className="beauty-header__wrapper">
				<h1 className="beauty-header__title">
					{ data.title }
				</h1>
				{ data?.beautyDescription &&
					<p className="beauty-header__description">{ data.beautyDescription }</p>
				}
			</div>
		</section>
	)
}

BeautyHeader.propTypes = {
	input: PropTypes.object.isRequired
};