import React from 'react'
import PropTypes from "prop-types"
import ReactMarkdown from 'react-markdown'

import Illustrations from '../../Illustrations/Illustrations'

export default function AboutContentBanner( props ) {

	const data = props.input

	return (
		<>
			<section className="about-content">
				<div className="about-content__wrapper wrapper">
					<div className="about-content__text-container">
						<ReactMarkdown source={ data.aboutContentBannerText } escapeHtml={ false } className="about-content__text" />
					</div>
					<div className="about-content__image-container">
						<Illustrations class="about-content__image" illustration={ data.aboutContentBannerIllustration } />
					</div>
				</div>
			</section>

		</>
	)
}

AboutContentBanner.propTypes = {
	input: PropTypes.object.isRequired
};