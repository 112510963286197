import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ReactMarkdown from 'react-markdown'

import SiteLogo from '../../../images/svgs/dream-on-logo.svg'
import AlitusLogo from '../../../images/svgs/alitus-logo.svg'
import BlossomLogo from '../../../images/svgs/blossom-logo.svg'

export default function ServicesBanner( props ) {

	const data = props.input

	return (
		<section className={`services-banner services-banner--${data.serviceType.toLowerCase()}`}>
			<div className="services-banner__wrapper">
				<div className="services-banner__text-container">
					<div className="services-banner__header">
						{
							{
								'DreamOn': <SiteLogo className="services-banner__logo" />,
								'Alitus': <AlitusLogo className="services-banner__logo" />,
								'Blossom': <BlossomLogo className="services-banner__logo" />
							}[data.serviceType]
						}
					</div>

					{ data.serviceInfo &&
						<ReactMarkdown source={ data.serviceInfo } escapeHtml={ false } className="services-banner__description" />
					}

					{ data.serviceLinkUrl && data.serviceLinkText &&
						<Link to={ data.serviceLinkUrl} className="services-banner__info">
							{ data.serviceLinkText }
						</Link>
					}
				</div>
			</div>
		</section>
	)
}

ServicesBanner.propTypes = {
	input: PropTypes.object.isRequired
};